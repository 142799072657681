<template>
  <!--
    Calendar for SKICON
  -->
  <div class="onCallDutyCalendar">
    <FullCalendar
      ref="calendar"
      :options="calendarOptions"
      locale="de"
    />
    <SweetModal
      ref="calendarEventModal"
      :title="openCalendarAddEventModal ? 'Add Event' : 'View / Edit Event'"
      blocking
      @close="closeModal"
    >
      <OnCallDutyAddEvent
        v-if="openCalendarAddEventModal"
        ref="addEventForm"
        :date-range="selectedDateRange"
        :role-colors="roleColors"
        @setLoading="setLoading"
        @closeAndReload="closeAndReload"
      />
      <OnCallDutyEditEvent
        v-if="openCalendarEditEventModal"
        ref="editEventForm"
        :selected-event="selectedEvent"
        :role-colors="roleColors"
        @setLoading="setLoading"
        @closeAndReload="closeAndReload"
      />
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.calendarEventModal.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />
        {{ $t('cancel') }}
      </button>
      <button
        v-if="(selectedEvent == null || (selectedEvent != null && selectedEvent.role != 'Holiday')) && (authenticationHasRole('admin') || authenticationHasRole('firstlevel') || authenticationHasRole('secondlevel'))"
        slot="button"
        class="btn btn-primary float-right mb-3"
        @click="saveEvent"
      >
        <font-awesome-icon
          class="mr-1"
          icon="check"
        />
        <span>{{ $t('save') }}</span>
      </button>
      <button
        v-if="openCalendarEditEventModal && (authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel'))"
        slot="button"
        class="btn btn-danger float-right mb-3 mr-3"
        @click="deleteElement"
      >
        <font-awesome-icon
          class="mr-1"
          icon="trash"
        />
        <span>{{ $t('remove') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import deLocale from "@fullcalendar/core/locales/de";
import { SweetModal } from "sweet-modal-vue";
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "OnCallDutyCalendar",
  components: {
    FullCalendar,
    SweetModal,
    OnCallDutyAddEvent: () =>
      import("@/components/OnCallDuty/OnCallDutyAddEvent.vue"),
    OnCallDutyEditEvent: () =>
      import("@/components/OnCallDuty/OnCallDutyEditEvent.vue"),
  },
  mixins: [
    dateTimeMixin,
    authenticationMixin
  ],
  props: {
    excludedTypes: {
      type: Array,
      required: true
    },
    roleColors: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        firstDay: 1,
        locale: "en",
        editable: false,
        selectable: true,
        selectMirror: true,
        datesSet: this.handleDatesSet,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        events: this.events,
        weekNumbers: true,
        weekText: 'KW',
        dayMaxEvents: 5,
        height: 'auto'
      },
      events: [],
      startDate: null,
      endDate: null,
      selectedDateRange: null,
      openCalendarAddEventModal: false,
      openCalendarEditEventModal: false,
      selectedEvent: null,
      filteredEvents: []
    };
  },
  watch: {
    "$i18n.locale" () {
      this.calendarOptions.locale = this.$i18n.locale;
    },
    excludedTypes () {
      this.filteredEvents = this.events.filter(x => !this.excludedTypes.some(e => e.name == x.role) || x.role == 'Holiday');
      this.calendarOptions.events = this.filteredEvents;
    }
  },
  created () {
    if (this.$i18n.locale == "de") {
      this.calendarOptions.locale = deLocale.code;
    }
  },
  mounted () {
    this.getCalendarData();
  },
  methods: {
    closeAndReload () {
      this.$refs.calendarEventModal.close();
      this.openCalendarEditEventModal = false;
      this.openCalendarAddEventModal = false;
      this.$emit('reloadAuditLogs');
      this.getCalendarData();
    },
    saveEvent () {
      if(this.openCalendarAddEventModal) {
        this.$refs.addEventForm.createEvent();
      }
      else if(this.openCalendarEditEventModal) {
        this.$refs.editEventForm.updateEvent();
      }
    },
    deleteElement () {
      this.$refs.editEventForm.deleteEvent();
    },
    closeModal () {
      this.selectedDateRange = null;
      this.openCalendarAddEventModal = false;
      this.openCalendarEditEventModal = false;
    },
    handleDatesSet (dateInfo) {
      if(!this.startDate) {
        this.startDate = dateInfo.start;
        this.endDate = dateInfo.end;
        this.getCalendarData();
      }
      else {
        let utcDateInfoStart = this.toMoment(dateInfo.start).utc();
        let utcDateStart = this.toMoment(this.startDate).utc();
        if(utcDateInfoStart.format() != utcDateStart.format()) {
          this.startDate = dateInfo.start;
          this.endDate = dateInfo.end;
          this.getCalendarData();
        }
      }
    },
    handleDateSelect (selectInfo) {
      if(this.authenticationHasRole('admin') || this.authenticationHasRole('secondlevel') || this.authenticationHasRole('firstlevel')) {
        this.selectedDateRange = selectInfo;
        this.openCalendarEditEventModal = false;
        this.openCalendarAddEventModal = true;
        this.$refs.calendarEventModal.open();
      }
    },
    handleEventClick (clickInfo) {
        this.selectedEvent = this.events.find(x => x.id == clickInfo.event.id);
        this.openCalendarAddEventModal = false;
        this.openCalendarEditEventModal = true;
        this.$refs.calendarEventModal.open();
    },
    setLoading (val) {
      this.$emit("setLoading", val);
    },
    getCalendarData () {
      this.$emit("setLoading", true);
      let req = {
        role: 'Any',
        start: this.startDate,
        end: this.endDate
      };

      this.axios
        .post(`/OnCallDuty/GetOnDutyCallServiceByRoleAndDate`, req)
        .then(response => {
          if(response && response.data) {
            this.filteredEvents = [];
            this.events = [];
            response.data.forEach(el => {
                this.events.push({
                  id: el.id,
                  title: el.skiconName,
                  start: el.dateFrom,
                  end: el.dateTo,
                  allDay: true,
                  contact: el.skicon,
                  contactName: el.skiconName,
                  role: el.role,
                  color: el.color
                });
            });
            this.filteredEvents = this.events.filter(x => !this.excludedTypes.some(e => e.name == x.role) || x.role == 'Holiday');
            this.calendarOptions.events = this.filteredEvents;
          }
        })
        .finally(() => { this.$emit("setLoading", false); });
    }
  }
};
</script>

<style>
.fc-h-event .fc-event-main  {
  cursor: pointer;
}

.onCallDutyCalendar .sweet-modal-overlay .sweet-modal {
  overflow: visible;
}
</style>